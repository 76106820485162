import React, { useEffect, useState } from "react";
import { Input, Select, Modal, Button, InputNumber, message } from "antd";
import { DatePicker, Table } from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";

const { RangePicker } = DatePicker;
const Option = Select.Option;

const CompletedBookingsScreen = () => {
  const [bookings, setBookings] = useState([]);
  const [users, setUsers] = useState();
  const [formatedBookings, setformatedBookings] = useState([]);
  const [chargersNames, setChargersNames] = useState();
  const [loading, setLoading] = useState(true);

  const [totalUnitsConsumed, setTotalUnitsConsumed] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [CGLFees, setCGLFees] = useState(0);

  const [dateRange, setDateRange] = useState();
  const [selectedChargerName, setSelectedChargername] = useState();
  const [filterSet, setfilterSet] = useState(false);
  const [selectedMobile, setSelectedmobile] = useState();
  const [unchangedFormatedTransactions, setUnchangedFormatedTransactions] =
    useState();
  const [selectedBooking, setSelectedBooking] = useState({});
  const [editCGlFeeModalOpen, setEditCGLFeeModalOpen] = useState(false);
  const [updatedCGLFees, setUpdatedCGLfees] = useState();

  const [updateBookingData, setUpdateBookingData] = useState(false);

  const [updatedMeterStop, setUpdatedMeterStop] = useState();
  const [updatedUnitsConsumed, setUpdatedUnitsConsumed] = useState();
  const [updatedPaidCredits, setUpdatedPaidCredits] = useState();
  const [updatedRefundCredits, setUpdatedRefundCredits] = useState();
  const [updatedWalletAmount, setUpdatedWalletAmount] = useState();
  const [updatedFinalWalletAmount, setUpdatedFinalWalletAmount] = useState();
  const [updatedRevenue, setUpdatedRevenue] = useState();

  const columns = [
    {
      title: "BookingId",
      dataIndex: "transactionId",
      fixed: "left",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.transactionId - b.transactionId,
      width: 100,
    },
    {
      title: "Name",
      dataIndex: "userName",
      fixed: "left",
      width: 180,
    },
    {
      title: "Mobile No.",
      dataIndex: "phone",
      width: 180,
    },
    {
      title: "Start Time",
      dataIndex: `startTime`,
      width: 150,
    },
    {
      title: "SuspendedEv",
      dataIndex: `suspendedEvTimeStamp`,
      width: 150,
    },
    {
      title: "End Time",
      dataIndex: "stopTime",
      width: 150,
    },
    {
      title: "CDT",
      dataIndex: "connectorRemovingTime",
      width: 150,
    },
    {
      title: "Charger Name",
      dataIndex: "chargerName",
      width: 180,
    },
    {
      title: "Meter Start",
      dataIndex: "meterStart",
      width: 120,
    },
    {
      title: "Meter Stop",
      dataIndex: "meterStop",
      width: 120,
    },
    {
      title: "Calf %",
      dataIndex: "calf",
      width: 100,
    },
    {
      title: "Calf Units Consumed",
      dataIndex: "UnitsConsumed",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.UnitsConsumed - b.UnitsConsumed,
      width: 110,
    },
    {
      title: "Electricity Price",
      dataIndex: "electricityChargePerkWh",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.electricityChargePerkWh - b.electricityChargePerkWh,
      width: 120,
    },
    {
      title: "Service Charge",
      dataIndex: "serviceChargePerkWh",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.serviceChargePerkWh - b.serviceChargePerkWh,
      width: 120,
    },
    {
      title: "Wallet Amount",
      dataIndex: "walletAmount",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.walletAmount - b.walletAmount,
      width: 120,
    },
    {
      title: "Blocked Amount",
      dataIndex: "blockedAmount",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.blockedAmount - b.blockedAmount,
      width: 120,
    },
    {
      title: "Refund Amount",
      dataIndex: "refund",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.refund - b.refund,
      width: 120,
    },
    {
      title: "Paid Amount",
      dataIndex: "paidCredits",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.paidCredits - b.paidCredits,
      width: 120,
    },
    {
      title: "Updated Wallet Amount",
      dataIndex: "finalWalletAmountAfterRefund",
      width: 120,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <>
          <a
            className="ml-5"
            onClick={() => {
              setSelectedBooking(record);
              setEditCGLFeeModalOpen(true);
            }}
          >
            <i class="fa-solid fa-file-invoice-dollar"></i>
          </a>
          <a
            className="ml-5"
            onClick={() => {
              setSelectedBooking(record);
              setUpdateBookingData(true);
            }}
          >
            <i
              style={{ color: "blue" }}
              class="fa-regular fa-pen-to-square"
            ></i>
          </a>
        </>
      ),
    },
  ];

  const getCompletedBookings = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/completed/bookings`, {
        token: localStorage.getItem("token"),
        timeStamp: dateRange || "50",
      });
      if (res?.data?.success) {
        // console.log(res?.data);
        setUsers(res?.data?.users);
        setBookings(res?.data?.bookings);
        setUnchangedFormatedTransactions(res?.data?.bookings);
        setChargersNames(res?.data?.chargersName);
        setCGLFees(res?.data?.data?.totalGunLockCharges);
        setTotalUnitsConsumed(res?.data?.data?.totalUnitsConsumed);
        setRevenue(res?.data?.data?.totalRevenue);
        formatBookingData(res?.data?.bookings, res?.data?.users);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function onChange(date, dateString) {
    let from = date[0]?.toString();
    let to = date[1]?.toString();

    var toDate;

    if (Number(to?.split(" ")[1]) < 10) {
      let d = Number(to?.split(" ")[1]) + 1;
      toDate = "0" + d;
    } else {
      toDate = Number(to?.split(" ")[1]) + 1;
    }

    let stamps =
      from?.split(" ")[1] +
      " " +
      from?.split(" ")[2] +
      " " +
      from?.split(" ")[3] +
      " " +
      toDate +
      " " +
      to?.split(" ")[2] +
      " " +
      to?.split(" ")[3];

    // console.log(stamps);

    setDateRange(stamps);
  }

  function convertTo12HourFormat(timeString) {
    // Parse the time string into a Date object
    const time = new Date(`2023-09-10T${timeString}`);

    // Get the hours, minutes, and seconds
    const hours = time.getHours();
    const minutes = time.getMinutes();
    // const seconds = time.getSeconds();

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time in 12-hour format
    const formattedTime = `${hours12 < 10 ? "0" + hours12 : hours12}:${String(
      minutes < 10 ? "0" + minutes : minutes
    )} ${period}`;

    return formattedTime;
  }

  const formatBookingData = (bookings) => {
    setLoading(true);
    try {
      let array = bookings;
      array?.map((booking) => {
        if (booking?.startTime) {
          booking.startTime =
            booking.startTime.split(" ")[1] +
            "/" +
            booking.startTime.split(" ")[2] +
            "/" +
            booking.startTime.split(" ")[3] +
            " " +
            convertTo12HourFormat(booking.startTime.split(" ")[4]);

          booking.stopTime =
            booking.stopTime.split(" ")[1] +
            "/" +
            booking.stopTime.split(" ")[2] +
            "/" +
            booking.stopTime.split(" ")[3] +
            " " +
            convertTo12HourFormat(booking.stopTime.split(" ")[4]);
        }
        if (booking?.connectorRemovingTime) {
          booking.connectorRemovingTime =
            booking.connectorRemovingTime.split(" ")[1] +
            "/" +
            booking.connectorRemovingTime.split(" ")[2] +
            "/" +
            booking.connectorRemovingTime.split(" ")[3] +
            " " +
            convertTo12HourFormat(booking.connectorRemovingTime.split(" ")[4]);
        } else {
          booking.connectorRemovingTime = "NA";
        }
        if (booking?.suspendedEvTimeStamp) {
          booking.suspendedEvTimeStamp =
            booking.suspendedEvTimeStamp.split(" ")[1] +
            "/" +
            booking.suspendedEvTimeStamp.split(" ")[2] +
            "/" +
            booking.suspendedEvTimeStamp.split(" ")[3] +
            " " +
            convertTo12HourFormat(booking.suspendedEvTimeStamp.split(" ")[4]);
        } else {
          booking.suspendedEvTimeStamp = booking?.disconnectReason;
        }
        return null;
      });
      setLoading(false);
      setUnchangedFormatedTransactions(array);
      return setformatedBookings(array);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const filterData = async () => {
    try {
      setLoading(true);
      if (selectedChargerName && selectedMobile) {
        // If all filters were seletced
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((booking) => {
          if (
            booking?.chargerName === selectedChargerName &&
            booking?.phone === selectedMobile
          ) {
            resultArray.push(booking);
          }
        });
        setformatedBookings(resultArray);
        setLoading(false);
      } else if (selectedChargerName) {
        // If only charger is selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultedArray = [];
        array?.map((booking) => {
          if (booking?.chargerName === selectedChargerName) {
            resultedArray.push(booking);
          }
        });
        setformatedBookings(resultedArray);
        setLoading(false);
      } else if (selectedMobile) {
        // If Only Mobile Number is selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultedArray = [];
        array?.map((booking) => {
          if (booking?.phone === selectedMobile) {
            resultedArray.push(booking);
          }
        });
        setformatedBookings(resultedArray);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompletedBookings();
  }, []);

  const handleUpdateCglFeesRequest = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/add/cgl/fees`, {
        token: localStorage.getItem("token"),
        _id: selectedBooking?._id,
        gunLockCharge: updatedCGLFees,
      });
      if (res?.data?.success) {
        getCompletedBookings();
        message.success("Updated successully.");
      } else {
        message.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRefundCglFeesRequest = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/refund/cgl/fees`, {
        token: localStorage.getItem("token"),
        _id: selectedBooking?._id,
      });
      if (res?.data?.success) {
        getCompletedBookings();
        message.success("Updated successully.");
      } else {
        message.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateBookingDetails = async () => {
    try {
      if (updatedMeterStop) {
        const res = await axios.post(
          `${BaseUrl}/update/completed/booking/data`,
          {
            token: localStorage.getItem("token"),
            bookingId: selectedBooking?._id,
            updatedMeterStop,
            updatedUnitsConsumed,
            updatedPaidCredits,
            updatedWalletAmount,
            updatedRevenue,
            updatedRefundCredits,
          }
        );
        if (res?.data?.success) {
          getCompletedBookings();
          setUpdateBookingData(false);
          message.success("Updated successully.");
        } else {
          message.error("Something went wrong. Please try again later.");
        }
      } else {
        message.warning("All fields required...");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (updatedMeterStop) {
      const TotalUnitsConsumedWithoutCalf = parseFloat(
        (Number(updatedMeterStop) - Number(selectedBooking?.meterStart)) / 1000
      ).toFixed(2);
      // console.log(TotalUnitsConsumedWithoutCalf);

      const calfAmount =
        (TotalUnitsConsumedWithoutCalf / 100) * Number(selectedBooking?.calf);

      // console.log(calfAmount);
      const TotalUnitsConsumed = (
        Number(TotalUnitsConsumedWithoutCalf) + calfAmount
      ).toFixed(2);
      setUpdatedUnitsConsumed(TotalUnitsConsumed);

      const TotalCharges = parseFloat(
        Number(selectedBooking?.electricityChargePerkWh) +
          Number(selectedBooking?.serviceChargePerkWh)
      ).toFixed(2);

      const revenue = parseFloat(
        TotalUnitsConsumed * Number(selectedBooking?.serviceChargePerkWh)
      ).toFixed(2);
      setUpdatedRevenue(revenue);

      const FinalDeductedAmount = parseFloat(
        TotalUnitsConsumed * TotalCharges
      ).toFixed(2);
      setUpdatedPaidCredits(FinalDeductedAmount);

      const blockedAmount = selectedBooking?.blockedAmount;

      // const user = await userModel.findOne({ idTag });

      if (blockedAmount >= FinalDeductedAmount) {
        let refundedAmount = parseFloat(
          blockedAmount - Number(FinalDeductedAmount)
        ).toFixed(2);
        setUpdatedRefundCredits("+ ₹ " + refundedAmount);

        const UserFinalWalletAmountAfterChargingStop = parseFloat(
          Number(selectedBooking?.walletAmount) +
            blockedAmount -
            FinalDeductedAmount
        ).toFixed(2);
        setUpdatedFinalWalletAmount(UserFinalWalletAmountAfterChargingStop);
      } else {
        let cutMoreCredits = parseFloat(
          FinalDeductedAmount - blockedAmount
        ).toFixed(2);
        setUpdatedRefundCredits("- ₹ " + cutMoreCredits);
        const UserFinalWalletAmountAfterChargingStop =
          Number(selectedBooking?.walletAmount) +
          blockedAmount -
          parseFloat(FinalDeductedAmount).toFixed(2);
        setUpdatedFinalWalletAmount(UserFinalWalletAmountAfterChargingStop);
      }
    }
  }, [updatedMeterStop]);

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Update CGL Fees Modal */}
      <Modal
        title="Charge CGL Fees :"
        centered
        open={editCGlFeeModalOpen}
        onCancel={() => {
          setEditCGLFeeModalOpen(false);
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setEditCGLFeeModalOpen(false);
            }}
          >
            Close
          </Button>,
          <Button
            danger
            onClick={() => {
              setEditCGLFeeModalOpen(false);
              selectedBooking.gunLockCharge
                ? handleRefundCglFeesRequest()
                : handleUpdateCglFeesRequest();
            }}
          >
            {selectedBooking.gunLockCharge ? "Refund CGL Fees" : "Update"}
          </Button>,
        ]}
      >
        <div className="mt-5 flex justify-center">
          <p style={{ width: 220 }} className="text-center pt-1 font-bold">
            Booking Id
          </p>
          <p className=" text-center pt-1 font-bold">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 font-bold">
            {selectedBooking?.transactionId}
          </p>
        </div>
        <div className="mt-1 flex justify-center">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            User
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            {selectedBooking?.userName}
          </p>
        </div>
        <div className="mt-1 flex justify-center">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Charger Name
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            {selectedBooking?.chargerName}
          </p>
        </div>
        <div className="mt-1 flex justify-center">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Start time
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            {selectedBooking?.startTime}
          </p>
        </div>
        <div className="mt-1 flex justify-center">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            SuspendedEv
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            {selectedBooking?.suspendedEvTimeStamp}
          </p>
        </div>
        <div className="mt-1 flex justify-center">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            End Time
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            {selectedBooking?.stopTime}
          </p>
        </div>
        <div className="mt-1 flex justify-center">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Connector Available At
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            {selectedBooking?.connectorRemovingTime}
          </p>
        </div>

        <div className="mt-5 flex flex-col justify-center items-center">
          {selectedBooking.gunLockCharge ? (
            <div className="mt-1 flex justify-center">
              <p style={{ width: 220 }} className=" text-center pt-1 ">
                CGL Fees
              </p>
              <p className=" text-center pt-1 ">:</p>
              <p style={{ width: 220 }} className=" text-center pt-1 ">
                {selectedBooking?.gunLockCharge}
              </p>
            </div>
          ) : (
            <>
              <p className="mt-2 mb-3 mr-2">Enter CGL Fees:</p>
              <InputNumber
                className="text-center"
                maxLength={3}
                showCount
                placeholder={selectedBooking?.cglFees}
                onChange={(e) => {
                  setUpdatedCGLfees(e);
                }}
                style={{
                  width: 120,
                }}
              />
            </>
          )}
        </div>

        <div className="flex w-full justify-center pt-3 pb-2"></div>
      </Modal>

      {/* Update Booking Details Modal */}
      <Modal
        title="Update Booking Data :"
        centered
        open={updateBookingData}
        onCancel={() => {
          setUpdateBookingData(false);
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setUpdateBookingData(false);
            }}
          >
            Close
          </Button>,
          <Button
            primary
            onClick={() => {
              handleUpdateBookingDetails();
            }}
          >
            Update
          </Button>,
        ]}
      >
        <div className="mt-5 flex justify-center">
          <p style={{ width: 220 }} className="text-center pt-1 font-bold">
            Booking Id
          </p>
          <p className=" text-center pt-1 font-bold">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 font-bold">
            {selectedBooking?.transactionId}
          </p>
        </div>
        <div className="mt-1 flex justify-center">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            User Name
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            {selectedBooking?.userName}
          </p>
        </div>
        <div className="mt-1 flex justify-center">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Charger Name
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            {selectedBooking?.chargerName}
          </p>
        </div>

        <div className="mt-1 flex justify-center">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Meter Start
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            {selectedBooking?.meterStart}
          </p>
        </div>

        <div className=" flex justify-center mt-3">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Meter Stop
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-0 ">
            <InputNumber
              className="text-center"
              // maxLength={3}
              showCount
              placeholder={selectedBooking?.meterStop}
              onChange={(e) => {
                setUpdatedMeterStop(e);
              }}
              style={
                {
                  // width: 120,
                }
              }
            />
          </p>
        </div>
        <div className="mt-1 flex justify-center">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            CALF
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            {selectedBooking?.calf}
          </p>
        </div>

        <div className="mt-3 flex justify-center">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Units Consumed
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center  ">
            {updatedUnitsConsumed
              ? updatedUnitsConsumed
              : selectedBooking?.UnitsConsumed}
          </p>
        </div>

        <div className="mt-1 flex justify-center">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Blocked Credits
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            {selectedBooking?.blockedAmount}
          </p>
        </div>

        <div className="mt-3 flex justify-center ">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Paid Credits
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center  ">
            {updatedPaidCredits
              ? updatedPaidCredits
              : selectedBooking?.paidCredits}
          </p>
        </div>

        <div className="mt-3 flex justify-center ">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Refund Credits
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center ">
            {updatedRefundCredits
              ? updatedRefundCredits
              : selectedBooking?.refund}
          </p>
        </div>

        <div className="mt-1 flex justify-center ">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Wallet Amount
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            {selectedBooking?.walletAmount}
          </p>
        </div>

        <div className="mt-1 flex justify-center ">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Revenue
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            {updatedRevenue ? updatedRevenue : selectedBooking?.revenue}
          </p>
        </div>

        <div className="mt-3 flex justify-center ">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Updated Wallet Amount
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center ">
            {updatedFinalWalletAmount
              ? updatedFinalWalletAmount
              : selectedBooking?.finalWalletAmountAfterRefund}
          </p>
        </div>

        <div className="flex w-full justify-center pt-3 pb-2"></div>
      </Modal>

      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Past/Completed Bookings
        </p>
      </div>

      {/* Filter Data */}
      <div className="mt-5 flex flex-col flex-wrap sm:flex-row ">
        <div className="flex">
          <p style={{ marginTop: 2 }}>Date Range</p>
          <RangePicker
            className="ml-4"
            style={{ width: 250 }}
            onChange={onChange}
          />
        </div>
        <a
          onClick={() => {
            getCompletedBookings();
            setfilterSet(false);
          }}
          className="text-center ml-10 pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
        >
          Get Range Data
        </a>
        {/* State */}
        <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-between ">
          <div className="flex">
            <p style={{ marginTop: 2 }}>Charger name</p>
            <Select
              className="ml-4"
              showSearch
              style={{ width: 200 }}
              placeholder="Select charger"
              optionFilterProp="children"
              onChange={(value) => setSelectedChargername(value)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {chargersNames &&
                chargersNames?.map((chargerName) => {
                  return (
                    <Option value={chargerName}>
                      {chargerName.toString()}
                    </Option>
                  );
                })}
            </Select>
          </div>
          {/* Select User */}
          <div className="flex ml-12">
            <p style={{ marginTop: 2 }}>Mobile No.</p>
            <Select
              className="ml-4"
              showSearch
              style={{ width: 300 }}
              placeholder="Select Mobile no."
              optionFilterProp="children"
              onChange={(value) => setSelectedmobile(value)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {users &&
                users?.map((user) => {
                  return (
                    <Option style={{ fontSize: 12 }} value={user?.phone}>
                      {"🧑🏻‍💼 " + user?.name + " 📞 " + user?.phone?.toLowerCase()}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div className="flex justify-center ml-12">
            <a
              onClick={() => {
                setSelectedChargername(undefined);
                setSelectedmobile(undefined);
                filterData();
              }}
              className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
            >
              Filter Data
            </a>
          </div>
        </div>
        {filterSet ? (
          <a
            onClick={() => {
              getCompletedBookings();
              setfilterSet(false);
              setSelectedChargername(undefined);
              setfilterSet(false);
              setSelectedmobile(undefined);
            }}
            className="mr-2 mt-6 ml-12"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>

      {/* Completed bookings View */}
      <div className="mt-4 overflow-auto">
        <Table
          columns={columns}
          dataSource={formatedBookings}
          scroll={{
            // x: 3100,
            y: 600,
          }}
        />
      </div>

      {formatedBookings?.length ? (
        <div className="flex justify-between my-5">
          <div>
            <p style={{ color: "gray" }}>
              Total Units Consumed : {totalUnitsConsumed} kWh
            </p>
          </div>
          <div>
            <p style={{ color: "gray" }}>CGL Fee : Rs {CGLFees}</p>
          </div>
          <div>
            <p style={{ color: "gray" }}>
              Total Revenue : Rs {revenue} (CGL Fee inc.)
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CompletedBookingsScreen;
