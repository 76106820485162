import React, { useState, useEffect } from "react";
import { Button, Select, message, Modal } from "antd";
import axios from "axios";
import { BaseUrl, websocketConnectionUrl } from "../../../static/BaseUrl.js";

const Screen = () => {
  const [wsGlobal, setWsGlobal] = React.useState();
  const [charegrStatusesArray, setCharegrStatusArray] = useState();
  const [rawResponseData, setRawResponseData] = useState();
  const [responseMessage, setResponseMessage] = useState();

  const [selectedCharger, setSelectedCharger] = useState();
  const [triggerMessageModalOpen, setTriggerMessageModalOpen] = useState(false);

  const [selectedTimeframe, setSelectedTimeframe] = useState();
  const [selectedCurrentOutput, setSelectedCurrentOutput] = useState();

  useEffect(() => {
    const charger = rawResponseData && rawResponseData.split("*")[1]?.trim();
    if (charger === selectedCharger) {
      if (
        rawResponseData &&
        rawResponseData.split("*")[2]?.split('"')[3] === "status" &&
        rawResponseData.split("*")[2]?.split('"')[1] === "SetChargingProfile"
      ) {
        const message =
          "Status : " + rawResponseData.split("*")[2]?.split('"')[5];
        setResponseMessage(message);
      }
      // else if (
      //   rawResponseData &&
      //   rawResponseData.split("*")[2]?.split('"')[3] === 'SendLocalListRequest'
      // ) {
      //   setResponseMessage(rawResponseData.split("*")[2]);
      // }
    }
  }, [rawResponseData]);

  const handleSendRequest = async (socket) => {
    try {
      setTriggerMessageModalOpen(true);
      let sendData = {
        To: "EWUPND00LE001",
        From: "BD8746G645VWU83",
        message: [
          2,
          "SetChargingProfile",
          "SetChargingProfile",
          {
            connectorId: 0,
            csChargingProfiles: {
              chargingProfileId: 0,
              stackLevel: 1,
              chargingProfilePurpose: "TxDefaultProfile",
              chargingProfileKind: "Absolute",
              chargingSchedule: {
                startSchedule: new Date(), // Adjust to the desired start time
                duration: selectedTimeframe, // Duration in seconds (1 hour)
                chargingRateUnit: "A",
                chargingSchedulePeriod: [
                  {
                    startPeriod: 0,
                    limit: selectedCurrentOutput, // Set the limit to 25 Amperes
                  },
                ],
              },
            },
          },
        ],
      };
      // console.log(sendData);

      await socket.send(JSON.stringify(sendData));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const charger = rawResponseData && rawResponseData.split("*")[1]?.trim();
    if (charger === selectedCharger) {
      if (
        rawResponseData &&
        rawResponseData.split("*")[2]?.split('"')[3] === "status" &&
        rawResponseData.split("*")[2]?.split('"')[1] === "StartTransaction"
      ) {
        const message =
          "Status : " + rawResponseData.split("*")[2]?.split('"')[5];
        setResponseMessage(message);
      }
    }
  }, [rawResponseData]);

  useEffect(() => {
    var ws = new WebSocket(websocketConnectionUrl);
    ws.onopen = () => {
      let sendData = {
        From: "BD8746G645VWU83",
      };
      ws.send(sendData);
      var refresh = true;
      setInterval(() => {
        refresh = true;
      }, 5000);

      ws.onmessage = (e) => {
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const chargersMap = new Map();
            const array = JSON.parse(arrayString);
            setCharegrStatusArray(array);
            array.map((charger) => {
              return chargersMap.set(charger[0], charger[1]);
            });
            if (refresh === true) {
              refresh = false;
              // setChargerStatus(chargersMap);
            }
            // setChargerStatus(mapFromJSON);
          } else {
            setRawResponseData(e.data);
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <p className="font-semibold text-xl mb-1">
        CMS Operation : Set Charging Profile
      </p>
      <p className="text-slate-600">
        A Central System can send a SetChargingProfile.req to a Charge Point, to
        set a charging profile, in the following situations: <br />
        • At the start of a transaction to set the charging profile for the
        transaction; <br />
        • In a RemoteStartTransaction.req sent to a Charge Point <br />
        • During a transaction to change the active profile for the transaction;
        <br />• Outside the context of a transaction as a separate message to
        set a charging profile to a local controller, Charge Point, or a default
        charging profile to a connector.
      </p>

      <Modal
        // title="Confirm"
        centered
        open={triggerMessageModalOpen}
        onCancel={() => {
          setTriggerMessageModalOpen(false);
          setResponseMessage("Waiting for response...");
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setTriggerMessageModalOpen(false);
              setResponseMessage("Waiting for response...");
            }}
          >
            Close
          </Button>,
          // <Button onClick={() => {setUnlockConnectorModalOpen(false) ; handleUnlockConnector()}}>Unlock</Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">Set Charging Profile</p>
        <p className="  text-center p-4 pt-8">
          {responseMessage ? responseMessage : "Waiting for response..."}
        </p>
        {/* <p className="font-bold text-center p-4">
          {selectedLocation && selectedLocation?.captiveCode}
        </p> */}
      </Modal>

      <div className="mt-24 flex justify-center">
        <Select
          style={{ width: 200, marginLeft: 20, marginRight: 20 }}
          showSearch
          className="shadow-xl"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          placeholder={"Select Charger"}
          onChange={(value) => setSelectedCharger(value)}
        >
          {charegrStatusesArray &&
            charegrStatusesArray?.map((charger) => {
              return (
                <option
                  style={{ color: charger[1] === "Unavailable" ? "red" : "" }}
                  value={charger[0]}
                >
                  {charger[0]}
                </option>
              );
              // if (charger[1] !== "Unavailable") {
              //   return <option value={charger[0]}>{charger[0]}</option>;
              // }
            })}
        </Select>

        {selectedCharger ? (
          <Select
            style={{ width: 100, marginLeft: 20, marginRight: 20 }}
            className="shadow-xl"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder={"Select current output"}
            onChange={(value) => setSelectedCurrentOutput(value)}
          >
            <option value={1}>1 A</option>
            <option value={2}>2 A</option>
            <option value={3}>3 A</option>
            <option value={4}>4 A</option>
            <option value={5}>5 A</option>
            <option value={6}>6 A</option>
            <option value={7}>7 A</option>
            <option value={8}>8 A</option>
            <option value={9}>9 A</option>
            <option value={10}>10 A</option>
            <option value={11}>11 A</option>
            <option value={12}>12 A</option>
            <option value={13}>13 A</option>
            <option value={14}>14 A</option>
            <option value={15}>15 A</option>
            <option value={16}>16 A</option>
            <option value={17}>17 A</option>
            <option value={18}>18 A</option>
            <option value={19}>19 A</option>
            <option value={20}>20 A</option>
            <option value={21}>21 A</option>
            <option value={22}>22 A</option>
            <option value={23}>23 A</option>
            <option value={24}>24 A</option>
            <option value={25}>25 A</option>
            <option value={26}>26 A</option>
            <option value={27}>27 A</option>
            <option value={28}>28 A</option>
            <option value={29}>29 A</option>
            <option value={30}>30 A</option>
            <option value={31}>31 A</option>
            <option value={32}>32 A</option>
          </Select>
        ) : (
          ""
        )}

        {selectedCurrentOutput ? (
          <Select
            style={{ width: 200, marginLeft: 20, marginRight: 20 }}
            className="shadow-xl"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder={"Set upto"}
            onChange={(value) => setSelectedTimeframe(value)}
          >
            <option value={3600}>1 hour</option>
            <option value={86400}>1 day</option>
            <option value={604800}>1 week</option>
            <option value={2592000}>1 month</option>
          </Select>
        ) : (
          ""
        )}
      </div>

      {selectedTimeframe ? (
        <div className="flex justify-center mt-10">
          <Button
            style={{ backgroundColor: "blue", color: "white" }}
            className="shadow-xl"
            primary
            onClick={() => {
              handleSendRequest(wsGlobal);
            }}
          >
            Send Request
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Screen;
